<template>
    <section>

        <div v-if="reAuthenticate" class="control  has-text-centered">
            <a class="button is-primary" 
                @click="reAuthBank">
                Login to Financial Instituation or bank to view latest transactions</a>
        </div>

            <div class="has-text-weight-semibold" v-if="bankAccountsAvailable">

                <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                    <div class="columns is-mobile">
                        <div class="column is-size-11 has-text-centered">BANK ACCOUNTS</div>                            
                    </div>
                </div>
                <div  v-if='showTransactions && ! reAuthenticate'>
                    <div class="">
                        <div class="columns">

                            <div class="column">
                                <b-field label="Bank Account">
                                    <b-select v-model="bankAccountId" placeholder="Select a bank account">
                                        <option
                                            v-for="option in bankAccounts"
                                            :value="option.id"
                                            :key="option.id">
                                            {{ option.accountName }} (***{{ option.mask }}) 
                                        </option>
                                    </b-select>
                                </b-field>                        
                            </div>

                            <div class="column">
                                <b-field>
                                    <date-range-selector
                                        :dateRange="transactionDates"
                                        @set-date-range="setDateRange"
                                        >
                                    </date-range-selector>                    
                                </b-field>                     
                            </div>
                        </div>            
                    </div>
                    <hr>


                <div class="is-large is-capitalized has-background-gray has-text-centered">
                    <h1 class="title">
                        {{ selectedAccountInstitution }} 
                        <b-button @click="removeBankAndAccounts">Remove</b-button>
                    </h1>
                    <h3 class="is-size-4 has-text-weight-bold">
                        {{ selectedAccountName }} (***{{ selectedAccountNumber }}) 
                    </h3>  
                    <h3 class="is-size-5 has-text-weight-bold">
                        {{ this.transactionDates[0] |formatDate }} - {{ this.transactionDates[1] | formatDate }}
                    </h3> 
                                
                    <hr>
                </div>
                <div class="panel-heading has-text-weight-bold">

                    <div class="columns">
                        <div class="column has-text-centered">
                            <div>
                            <p class="is-size-7">Total Debits</p>
                            <p class="is-size-4"> {{ totalDebits | formatCurrency }}</p>
                            </div>
                        </div>   

                        <div class="column has-text-centered">
                            <div>
                            <p class="is-size-7">Total Credits</p>
                            <p class="is-size-4"> {{ totalCredits * -1 | formatCurrency }}</p>
                            </div>
                        </div>   

                        <div class="column has-text-centered">
                            <div>
                            <p class="is-size-7">Current Balance</p>
                            <p class="is-size-4" :class="{'has-text-danger': selectedCurrentBalance < 0.00 }"> {{ selectedCurrentBalance | formatCurrency }}</p>
                            </div>
                        </div>

                        <div class="column has-text-centered">
                            <div>
                            <p class="is-size-7">Available Balance</p>
                            <p class="is-size-4" :class="{'has-text-danger': selectedAvailableBalance < 0.00 }"> {{ selectedAvailableBalance | formatCurrency }}</p>
                            </div>
                        </div>                    
                    </div>
                </div> 
                <br>   
                <b-table
                    @filters-change="filterChanged"
                    :backend-filtering="bankendFiltering"
                    :data='transactions'
                    :paginated="isPaginated"
                    :per-page="perPage"
                    paginationPosition="both"
                    sortIcon="arrow-up"
                    sortIconSize="is-small"
                    default-sort=date
                    defaultSortDirection='desc'
                    >

                    <template>
                        <b-table-column field="date" label="Date" v-slot="props" sortable sorticon>
                            {{ props.row.date | formatDate }}
                        </b-table-column>                      
                        <b-table-column field="name" label="Name" v-slot="props" sortable sorticon searchable>
                            <div class="is-hidden-mobile">
                                {{ props.row.name }}
                            </div>
                            <div class="is-hidden-desktop">
                                <span>{{ props.row.name.slice(0, 25) }}</span>
                                <span v-if="props.row.name.length > 25"><br>{{ props.row.name.slice(25, 50) }}</span>
                                <span v-if="props.row.name.length > 50"><br>{{ props.row.name.slice(50, 75) }}</span>
                                <span v-if="props.row.name.length > 75"><br>{{ props.row.name.slice(75, 100) }}</span>
                                <span v-if="props.row.name.length > 100"><br>{{ props.row.name.slice(100, 125) }}</span>
                            </div>
                        </b-table-column>

                        <b-table-column field="referenceNumber" label="Reference #" v-slot="props" sortable sorticon>
                            {{ props.row.referenceNumber }}
                        </b-table-column>                    
                        <b-table-column field="category" label="Category" v-slot="props" sortable sorticon>
                            {{ props.row.category }}
                        </b-table-column>
                        <b-table-column field="transactionType" label="Type" v-slot="props" sortable sorticon>
                            {{ props.row.transactionType }}
                        </b-table-column>
                        <b-table-column numeric field="amount" label="Debit" v-slot="props" sortable sorticon>
                            <span v-if="props.row.amount > 0">{{ props.row.amount | formatCurrency }}</span>
                        </b-table-column>                    
                        <b-table-column numeric field="amount" label="Credit" v-slot="props" sortable sorticon>
                            <span v-if="props.row.amount <= 0">{{ props.row.amount * -1 | formatCurrency }}</span>
                        </b-table-column>                    
                    </template>
                <!-- <template slot="top-left">
                    <b-field>
                        <date-range-selector
                            :dateRange="transactionDates"
                            @set-date-range="setDateRange"
                            >
                        </date-range-selector>                    
                    </b-field>                                      
                </template> -->
                <template slot="empty">
                    <data-loading 
                        :isLoading="isLoading" 
                        :isFullPage="isFullPage"> 
                    </data-loading>
                </template>
                </b-table>
                <br>
                <div class="panel-heading has-text-weight-bold">

                    <div class="columns">
                        <div class="column has-text-centered">
                            <div>
                            <p class="is-size-7">Total Debits</p>
                            <p class="is-size-4"> {{ totalDebits | formatCurrency }}</p>
                            </div>
                        </div>   

                        <div class="column has-text-centered">
                            <div>
                            <p class="is-size-7">Total Credits</p>
                            <p class="is-size-4"> {{ totalCredits * -1 | formatCurrency }}</p>
                            </div>
                        </div>   

                        <div class="column has-text-centered">
                            <div>
                            <p class="is-size-7">Current Balance</p>
                            <p class="is-size-4" :class="{'has-text-danger': selectedCurrentBalance < 0.00 }"> {{ selectedCurrentBalance | formatCurrency }}</p>
                            </div>
                        </div>

                        <div class="column has-text-centered">
                            <div>
                            <p class="is-size-7">Available Balance</p>
                            <p class="is-size-4" :class="{'has-text-danger': selectedAvailableBalance < 0.00 }"> {{ selectedAvailableBalance | formatCurrency }}</p>
                            </div>
                        </div>                    
                    </div>
                </div> 
            </div>
        </div>


        <div class="columns is-desktop" v-if="! bankAccountsAvailable">
            <div class="column has-text-centered">
                <div>
                    <img src="/images/veecli-logo.png">
                    <br>
                    <span class="title is-1">VEECLi</span>

                    <hr>

                    <p class="title is-3">Data not Available!</p>
                    <hr>
                    <p class="is-size-5">Bank Account not configured.</p>
                    <hr>

                    <b-button class="is-primary" tag="router-link" :to="{ name: 'StoreBankRegister' }">
                     Register your Bank Account
                    </b-button>

                </div>               
            </div>
        </div>

    </section>
</template>

<script>
import UserStore from "../../store/UserStore"

import dataLoading from '../../components/app/DataLoading'
import DateRangeSelector from "../../components/app/DateRangeSelector"
import router from '../../router'
import axios from 'axios'
// import moment from 'moment'

export default {

    components: {
        dataLoading,
        DateRangeSelector
    },

    metaInfo: {
        title: 'Bank Accounts'
    },

    data() {
        return {
            bankAccountsAvailable: true,
            banks: [],
            bankAccounts: [],
            bankId: 0,
            bankAccountId: 0,
            totalDebits: 0,
            totalCredits: 0,
            transactions: [],
            selected: {},
            selectedAccountNumber: '',
            selectedAccountInstitution: '',
            selectedAccountName: '',
            selectedCurrentBalance: 0,
            selectedAvailableBalance: 0,
            plaid: null,
            plaidClientName: 'VEECLi',
            plaidEnv: 'production',
            plaidPublicKey: '5a6d1ba3cb0abefdbaf33536dbe14d',
            plaidProduct: ['auth','transactions'],
            user: [],
            isPaginated: true,
            perPage: 25,
            isLoading: true,
            isFullPage: false,
            bankendFiltering: false,
            maxDate: new Date(),
            transactionDates: [new Date(), new Date()],
            reAuthenticate: false,
        }
    },

    methods: {

        fetchStoreBankAccounts() {
            if (this.user.activeStore) {

                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/banking'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                        this.banks = this.arraySortBy(response.data.data,"account.institutionName")
                        
                        this.banks.forEach(bank => {
                            bank.bankAccounts.forEach( account => {
                                account.bankId = bank.id
                                account.institutionName = bank.institutionName
                                if (account.active) {
                                    this.bankAccounts.push(account)
                                }
                                if (this.bankAccountId == 0) {
                                    this.bankAccountId = account.id
                                }
                            })
                        });



                        this.bankAccounts = this.arraySortBy(this.bankAccounts,"accountName")

                        this.selected = this.bankAccounts[0]
                        // this.bankId = this.bankAccounts[0].id
                        this.bankId = this.banks.id
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        this.isLoading = false
                        if (err.response.status === 404) {
                            this.bankAccountsAvailable=false
                        }

                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }

                        if (err.response.status === 400 && err.response.data.errorKey == "ITEM_LOGIN_REQUIRED") {
                            
                            this.reAuthenticate = true

                            this.reAuthBank()
                            
                        }
                        
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)

                    })
            }            
        },

        arraySortBy(array,p) {

            return array.sort(function(a,b) {

                return (a[p] > b[p]) ? 1 : (a[p] < b[p]) ? -1 : 0;

            })
        },

        fetchAccountTransactions(id) {

            if (id > 0) {

                this.isLoading = true
                this.transactions = []
                this.bankAccountId = id
                var startDate = this.transactionDates[0].toISOString().split('T')[0]
                var endDate = this.transactionDates[1].toISOString().split('T')[0]

                const url = process.env.VUE_APP_API_ROOT_URL + '/bankaccount/' + id 
                            + '/transactions?size=300'  + "&startDate="+startDate+"&endDate="+endDate
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.transactions = response.data.data.transactions.content
                        this.setTotalDebits()
                        this.setTotalCredits()
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        // console.log(err)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false

                        if (err.response.status === 403) {
                        
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }                    

                        if (err.response.status === 400 && err.response.data.errorKey == "ITEM_LOGIN_REQUIRED") {
                            
                            this.reAuthenticate = true

                            this.reAuthBank()
                            
                        }

                    })
            }
        },

        setTotalDebits() {

            var debits = this.transactions.filter((item) => item.amount > 0 )
            this.totalDebits = debits.reduce((accum,item) => accum + parseFloat(item.amount), 0) 
        },

        setTotalCredits() {

            var credits = this.transactions.filter((item) => item.amount < 0 )
            this.totalCredits = credits.reduce((accum,item) => accum + parseFloat(item.amount), 0) 
        },

        applyFilter() {
            this.fetchAccountTransactions(this.bankAccountId)
        },

        setDateRange(dateRange) {
            this.transactionDates = [dateRange[0], dateRange[1]]
        },              

        rowSelected(row) {
            
            this.selectedAccountNumber=row.mask
            this.selectedAccountName=row.accountName
            this.selectedAvailableBalance=row.available
            this.selectedCurrentBalance=row.current
            this.selectedAccountInstitution=row.institutionName
            this.totalDebits = 0.00
            this.totalCredits = 0.00
            this.fetchAccountTransactions(row.id)
        },

        accountSelected() {
            
            if (this.bankAccountId && this.bankAccounts.length > 0 ) {
                var row = this.bankAccounts.find(account => account.id == this.bankAccountId)
                if (row) {
                    this.rowSelected(row)
                } else {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: 'Failed to collect account balance. Please try again later.',
                        position: 'is-top',
                        type: 'is-danger'
                    })
                }
            }
        },

        reAuthBank() {
                this.$buefy.dialog.confirm({
                    title: 'Re-Authenticate to Financial Institution',
                    message: `<p class="has-text-danger is-size-5">Authorization to view account transactions expired or invalid.  Re-enter your Financial Instutution login details.</p><br>
                              <p>Some financial instituations require to validate the authentication every few days to protect your information from 
                              unauthorized access.</p><br><p>Please click continue and enter your user name and password to re-authenticate to your Financial Institution/Bank.</p>`,
                    cancelText: 'Maybe Later',
                    confirmText: 'Continue',
                    type: 'is-success',
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    onConfirm: () => {

                        this.reLoginToBank()
                        
                    },
                    onCancel: () => {
                        this.$buefy.toast.open('You must complete to view transactions.')
                    }
                })         
        },

        reLoginToBank() {
            if (this.user.activeStore) {

                this.removeBankAndAccounts()
                
                var bankId = this.banks[0].id

                const url = process.env.VUE_APP_API_ROOT_URL + '/banking/' + bankId + '/generate_public_token'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        var publicKey = response.data.data
                        // console.log(publicKey)
                        this.openPlaidForm(publicKey)                        
                    })
                    .catch( (err) => { 
                        console.log(err)
                    })
            }
        
        },

        openPlaidForm(publicKey) {

            this.initPlaid()

            if (this.plaid != null) { 
                // console.log("Public Key",publicKey)
                var self = this
                this.plaid.create({
                    token: publicKey,
                    env: this.plaidEnv,
                    product: "auth",
                    onLoad: () => {
                        self.onLoad()
                    },
                    onSuccess: (public_token, metadata) => {
                        self.onSuccess(public_token, metadata)
                    },
                    onExit: (err, metadata) => {
                        self.onExit(err, metadata)
                    },
                    onEvent: (eventName, metadata) => {
                        self.onEvent(eventName, metadata)
                    }

                }).open()
            } else {
                // console.log('Plaid is empty')
            }

        },

        initPlaid() {
            if (this.plaid == null) { 
                let initPlaid = document.createElement('script')
                initPlaid.async = true
                initPlaid.setAttribute('src', 'https://cdn.plaid.com/link/v2/stable/link-initialize.js')
                document.head.appendChild(initPlaid)

                initPlaid.onload = () => {
                    this.plaid = window.Plaid
                }
            }

        },
    
        onLoad() {

        },
        
        filterChanged(filter) {
            console.log("Filter Changed", filter)
        },

        onSuccess(public_token, metadata) {

            if (public_token) {
                // Do Nothing
            }

            if (metadata) {
                // Do Nothing
            }
 
            // console.log("Successful")

        },

        removeBankAndAccounts() {
            this.$buefy.dialog.confirm({
                title: 'Deleting Bank Account Access',
                message: 'Are you sure you want to <b>delete</b> your bank and accounts? This action cannot be undone.',
                confirmText: 'Delete Account',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.deleteBank()
                    this.$buefy.toast.open({
                        message: 'Are you sure? Bank and Accounts will be deleted.',
                        type: 'is-info'
                    })
                }
            })
        },

        deleteBank() {
            if (this.user.activeStore) {

                var bankId = this.banks[0].id

                const url = process.env.VUE_APP_API_ROOT_URL + '/banking/' + bankId 

                axios.delete(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( () => {
                        this.bankAccountId = 0
                        this.banks = []
                        this.bankAccounts = []
                        this.bankAccountsAvailable=false
                        router.push({ path: '/store/banks/accounts' })
                        // console.log(response)                    
                    })
                    .catch( (err) => { 
                        console.log(err)
                    })
            }

        },
        
        onExit(err, metadata) {
            if (process.env.VUE_APP_DEBUG == 'true') console.log(err, metadata)
            this.isLoading = false     
            
            this.$buefy.notification.open({
                    message: 'Failed to save bank information. Please contact support@veecli.com!',
                    type: 'is-danger',
                    duration: 6000,
                    'has-icon': true,
                    'icon-pack': 'fas',
                    icon: 'exclamation-circle'
                })  
        },

        onEvent(eventName, metadata) {
            console.log('event', eventName, metadata)
        },    

    },

    watch: {
        transactionDates: function() {
            this.applyFilter()
        },

        bankAccountId: function() {
            this.accountSelected()
            this.applyFilter()
        },        
    },

    computed: {

        showTransactions() {
            return true
        },   
    },

    mounted() {
        this.user = this.getUser()
        var firstDay=new Date()
        firstDay.setDate(firstDay.getDate() - firstDay.getDate() + 1)
        this.transactionDates=[firstDay, new Date()]
        this.fetchStoreBankAccounts()
        this.initPlaid()
    },


}
</script>

<style>
tr:nth-child(even) {
  background-color: #f2f2f2;
}
</style>